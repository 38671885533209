import React, { useState } from "react"
import Typed from "react-typed"
import { GiHamburgerMenu } from "react-icons/gi"

import { NavLink } from "../styles/styledComponent"

const Header = () => {
  const [isActive, setIsActive] = useState(false)

  return (
    <header className="fixed-top header sec-type-1">
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <div className="navbar-item">
              <NavLink to="/" fontWeight="bold">
                <Typed
                  strings={["Rishav Pandey", "ऋषभ पांडेय"]}
                  typeSpeed={60}
                  backSpeed={60}
                  loop
                  className="typed-span"
                />
              </NavLink>
            </div>
            <button
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              onClick={() => {
                setIsActive(!isActive)
              }}
            >
              <GiHamburgerMenu />
            </button>
          </div>
          <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
            <div className="navbar-end">
              <div className="navbar-item">
                <NavLink to="/" border="true" activeClassName="current-page">
                  Home
                </NavLink>
              </div>
              <div className="navbar-item">
                <NavLink
                  to="/about"
                  border="true"
                  activeClassName="current-page"
                >
                  About
                </NavLink>
              </div>
              <div className="navbar-item">
                <NavLink
                  to="/portfolio"
                  border="true"
                  activeClassName="current-page"
                >
                  Portfolio
                </NavLink>
              </div>
              <div className="navbar-item">
                <NavLink
                  to="/contact"
                  border="true"
                  activeClassName="current-page"
                >
                  Contact
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
