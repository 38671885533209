import BackgroundImage from "gatsby-background-image"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import scssVariables from "../styles/sass/global.scss"

export const NavLink = styled(Link)`
  color: ${scssVariables.fontColorType1};
  font-size: 1.2rem;
  font-family: ${scssVariables.headingFontFamily};
  font-weight: ${props => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 0.5rem;
  padding: 0.25rem;
  text-decoration: none;
  border-bottom: ${props =>
    props.border ? `2px solid ${scssVariables.bgColorType1}` : "none"};

  &.current-page {
    border-bottom: 2px solid ${scssVariables.fontColorType1};
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const SecMainHead = styled.h1`
  font-family: ${scssVariables.headingFontFamily};
  display: inline-block;
  width: fit-content;
  font-size: ${scssVariables.headingLargeFontSize};
  font-weight: 600;
  padding: 10px;
  margin-bottom: 0px;
  border-top: 1px solid ${scssVariables.websiteThemeColor};
  border-bottom: 1px solid ${scssVariables.websiteThemeColor};
`

export const CardMainHead = styled.h3`
  font-family: ${scssVariables.headingFontFamily};
  display: inline-block;
  width: fit-content;
  font-size: ${scssVariables.headingMediumFontSize};
  font-weight: 600;
  padding: 10px;
  margin-bottom: 0px;
  position: relative;
  &::after {
    content: "";
    background-color: ${scssVariables.websiteThemeColor};
    position: absolute;
    height: 3px;
    width: 40px;
    right: ${props => props.rightLarge || "43%"};
    bottom: 3px;
    @media only screen and (max-width: 900px) {
      right: ${props => props.rightMedium || "40%"};
    }
    @media only screen and (max-width: 768px) {
      right: ${props => props.rightSmall || "41%"};
    }
  }
`

export const ThemeButton = styled.button`
  background-color: ${props => props.bgColor || "transparent"};
  border: 3px solid
    ${props => props.borderColor || scssVariables.websiteThemeColor};
  padding: 10px 20px;
  font-weight: ${props => props.fontWeight || "normal"};
  font-size: 1.2em;
  border-radius: 0.25rem;
  color: ${props => props.fontColor || scssVariables.fontColorType1};
  cursor: pointer;
`
export const SecImageBackground = styled(BackgroundImage)`
  background-position: top center;
  background-size: cover;
  height: 100%;
`
