import React from "react"
import {
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
  FaStackOverflow,
  FaFacebookF,
  FaInstagram,
  FaHeart,
  FaWhatsapp,
  FaYoutube
} from "react-icons/fa"

const Footer = () => (
  <footer className="sec-type-1">
    <div className="container">
      <div className="grid-view md-2-col-grid">
        <div className="social-icon-list">
          <div className="icons">
            <a
              href="https://linkedin.com/in/rishavpandey43/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="fa-1-5x" />
            </a>
          </div>
          <div className="icons">
            <a
              href="https://twitter.com/rishavpandey43"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="fa-1-5x" />
            </a>
          </div>
          <div className="icons">
            <a
              href="https://github.com/rishavpandey43"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="fa-1-5x" />
            </a>
          </div>
          <div className="icons">
            <a
              href="https://stackoverflow.com/users/7888165/rishav-pandey"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaStackOverflow className="fa-1-5x" />
            </a>
          </div>
          <div className="icons">
            <a
              href="https://www.instagram.com/rishavpandey43/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="fa-1-5x" />
            </a>
          </div>
          <div className="icons">
            <a
              href="https://www.youtube.com/channel/UCxoiHeIbbHj99e889dFKiAg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="fa-1-5x" />
            </a>
          </div>
          <div className="icons">
            <a
              href="https://facebook.com/rishavpandey43"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="fa-1-5x" />
            </a>
          </div>

          <div className="icons">
            <a
              href="https://wa.me/919771578320?text=Hello Rishav.%0A%0A%0AI hope you're doing great.%0A%0AI got your number through your website and was interested to talk with you."
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="fa-1-5x" />
            </a>
          </div>
        </div>
        <div className="copyright">
          <p>
            © 2020 <em>Rishav Pandey</em>. All Right Reserved.
          </p>
        </div>
      </div>
      <div className="lower-footer">
        <p>
          Made with <FaHeart className="fa-icon fa-1-5x" /> in India
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
